
.auth-column-head {
    color: $VTGrey;
    font-weight: bold;
    font-size: 1.5em;
    float: left
}

.auth-text-greyed {
    color: $VTGrey;
    float: left;
}

.auth-buttons {
    background-color: $VTBlue;
    border-radius: 0%;
    width: 100%;
}

.auth-column-width {
    width: 300px;
}

.auth-text-fields {
    font-size: 1.2em;
    text-align: left;
}

.hidden-pw {
    display: none;
}

.auth-error-text {
    margin-bottom: 0rem;
}
