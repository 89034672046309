.no-cred-message-container {
    margin-top: 15%;
    margin-bottom: 15%
}

.no-cred-contact-message {
    margin-bottom: 1rem;
    color: $VTGrey;
}

.no-cred-contact-us {
    margin-top: 2rem;
    color: $VTGrey;
}

.section-heading {
    color: $VTGrey;
    font-weight: bold;
    margin-top: 1rem;
}

.selected-cred-label {
    color: $VTGrey;
    font-size: 1.10rem;
}

.selected-cred-val {
    font-size: 1.05rem;
}

.selected-cred-review-msg {
    margin-top: 5%;
}

.ReactTable {
    margin-bottom: 30px;
}

.rt-thead {
    &.-filters, &.-filters .rt-th {
        overflow: unset;
    }

    .rt-th {
        font-weight: bold;
        text-align: left !important;
    }

    .rt-th input[type='checkbox'] {
        position: relative;
        left: 4px;
        top: 2px;
    }
}

.rt-tbody {
    .rt-tr {
        cursor: pointer;
    }
}
.select-cred-actions-group {
    align-items: flex-end;
}

.light-grey-text {
    color: lightgrey
}
