.edit-confirmation {
    .grey-label {
        font-weight: bold;
        color: $VTGrey;
    }
    .brown-label {
        font-weight: bold;
        color: $VTLabel;
    }
    .black-label {
        font-weight: bold;
        color: #000;
    }
    .bottom-text {
        color: $VTGrey;
    }
    .address-label {
        color: $VTGrey;
    }
}

.edits-column-head {
    color: $VTGrey;
    font-weight: bold;
    font-size: 1.5em;
    float: left
}

.edits-text-greyed {
    color: $VTGrey;
    float: left;
}

.real-id-divider {
    color: rgba(0,0,0,0.125);
    background-color: rgba(0,0,0,0.125);
    height: 1px;
}

