
.confirmation-label {
    font-size: 1.20rem;
    font-weight: bold;
    color: $VTGrey;
}

.confirmation-value {
    font-size: 1.20rem;
    font-weight: bold;
    color: black;
}

.confirmation-text {
    font-size: 1rem;
    color: $VTGrey;
}

.error-logout-btn {
    background-color: #721c24;
    float: right;
}
