$VTGreen: #030;
$VTLabel: #8B5F3A;
$VTGrey: #787276;
$VTBlue: #0D3C99;
$VTTan: #A8A887;

@import '~react-table/react-table.css';
@import '~ladda/dist/ladda.min.css';
@import '~text-security/text-security.css';
@import '~react-datepicker/dist/react-datepicker.min.css';
@import 'logo-styles';
@import 'AuthPage';
@import 'CredentialSelection';
@import 'ConfirmationPage';
@import 'EditConfirmation';
@import 'TransactionHistory';

body {
    // margin-left: 15px;
    width: 100%;
    font-family: "Lato",Verdana,Tahoma,"DejaVu Sans",sans-serif;
}

// .row {
//     margin-right: 0px;
// }

label {
    color: $VTLabel;
    font-weight: 700;
    float: left;
}

.label-color {
    color:  $VTLabel;
}

.greyed-text {
    color: $VTGrey;
}

.ladda-button.disabled-ladda {
    background-color: #aaa;
    cursor: default;
}

.valid-password-field {
    font-family: text-security-disc;
    -webkit-text-security: disc;
}

.top-header-bar {
    padding-top: 8px;
    padding-bottom: 8px;
    color: white;
    background-color: $VTGreen;
    min-height: 40px;

    .official-site-col {
        font-size: 0.75em;
        font-weight: 300;
        letter-spacing: 0.378px;
        color: white;
        text-transform: uppercase;
        padding-top: 4px;
        padding-bottom: 4px;

        .official-site-container {
            float: left;
        }
        a:link {
            text-decoration: inherit;
            text-decoration-style: solid;
            color: inherit;
        }

        a:visited {
            text-decoration: inherit;
            text-decoration-style: solid;
            color: inherit;
        }
    }
}

.button-group {
    margin-top: 1rem;
}

.secondary-header-bar {
    background-color: #EFEFE3;
    min-height: 142px;
    color: $VTGreen;
}

.second-header-text-container {
    padding-top: 1em;
    padding-bottom: 1em;
}

.header-agency-text {
    font-size: 0.9em;
    text-align: left;
}

.header-dept-text {
    text-align: left;
    font-size: 1.8em;
    font-weight: 700;

    a:link {
        text-decoration: inherit;
        text-decoration-style: solid;
        color: inherit;
    }

    a:visited {
        text-decoration: inherit;
        text-decoration-style: solid;
        color: inherit;
    }
}
.language-picker-container {
    float: right;
    margin-top: 1em;

    .language-picker {
        width: 250px;
    }
}

.general-btn {
    background-color: $VTBlue;
    border-radius: 0%;
    width: 100%;
}

.cancel-btn {
    background-color: $VTGrey;
    border-radius: 0%;
    width: 100%;
}

.title {
    color: $VTGrey;
    font-weight: bold;
    font-size: 2.25rem;
}

.sub-title {
    color: $VTGrey;
    font-weight: bold;
}

.form-check-input {
    position: unset;
    margin-top: .5rem;
    margin-left: unset;
}

label {
    margin-top: .5rem;
}

.margin-top-halfrem {
    margin-top: .5rem;
}

.margin-bottom-20 {
    margin-bottom: 25px;
}

.ladda-button {
    padding: .375rem .75rem;
    font-size: 1rem;
}

.min-width-350 {
    min-width: 350px;
}

.width-80 {
    width: 80px;
}

.width-50 {
    width: 50px;
}

.react-datepicker-wrapper {
    width: 100%;
}

.help-icon {
    background-color: $VTLabel;
    color: white;
    width: 1.5rem;
    display: inline-block;
    border-radius: 10%;
    text-align: center;
    cursor: pointer;
}

.help-image-container {
    text-align: center;

    .help-images {
        max-width: 90%;
    }
}

.loading-icon {
    color: $VTLabel
}
